import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./FeaturesSection4.scss";

function FeaturesSection4(props) {
  const items = [
    {
      helper: "Biz Kimiz?",
      title: "Biz mühendisler, tasarımcılar ve mimarlardan oluşan bir ekibiz.",
      description: (
        <div>
          <p className={"is-size-4 has-text-success-dark"}>
            Vaat ettiğini yapmayan hiçbir ürün dünyaya katkı sağlamamaktadır. Bu
            nedenle, her zaman kullanım kolaylığı sağlayan ve herkes için
            keyifli deneyimler yaratan güzel, basit tasarımlı ürünler sunmaya
            çalışıyoruz.
          </p>
          <p className={"mt-4 is-size-5"}>
            Teknolojimiz Türkiye/Ankara doğdu. Tüm ürünlerimiz, ekibimize
            sistemin performansı üzerinde tam kontrol sağlayacak şekilde şirket
            içinde tasarlanmıştır. Avrupa ve Asya'daki geniş meslektaşlar,
            ortaklar ve işbirlikçi ağımız, gerçek anlamda küresel bir ürün
            oluşturmak için mümkün olan tüm zaman dilimlerinde çalışmaktadır.
          </p>
        </div>
      ),
      image:
        "https://assets-global.website-files.com/60b908aee9c9240efc4b480e/60ee5566bc84e647ec4fed5c_office.jpg",
    },
  ];

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <div className="has-text-centered">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={1}
            spaced={true}
            className="has-text-centered"
          />
        </div>
        <div className="FeaturesSection4__features">
          {items.map((item, index) => (
            <div
              className="FeaturesSection4__columns columns is-variable is-vcentered has-text-centered-mobile is-12"
              key={index}
            >
              <div className="column is-5">
                <p>{item.helper}</p>
                <h3 className="FeaturesSection4__title title has-text-weight-bold is-spaced is-2">
                  {item.title}
                </h3>
                <p className="subtitle">{item.description}</p>
              </div>
              <div className="column">
                <figure className="FeaturesSection4__image image">
                  <img src={item.image} alt={item.title} />
                </figure>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection4;
