import React from "react";
import Meta from "./../components/Meta";
import FeaturesSection4 from "./../components/FeaturesSection4";
import ClientsSection from "./../components/ClientsSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <FeaturesSection4
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Hedefimiz"
        subtitle="Dünyanın kaynaklarını koruyarak, insan ve yapılı çevre arasında daha zengin etkileşimi sağlamak."
      />
      <ClientsSection
        color="white"
        size="small"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Destekleyen Kurumlar"
        subtitle=""
      />
    </>
  );
}

export default AboutPage;
