import React from "react";
import "./../styles/global.scss";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import RegisterPage from "./register";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";

function App(props) {
  return (
    <Router>
      <>
        <Navbar color="white" spaced={true} logo="../assets/EcoSpace.svg" />

        <Switch>
          <Route exact path="/" component={IndexPage} />

          <Route exact path="/about" component={AboutPage} />

          <Route exact path="/register" component={RegisterPage} />

          <Route component={NotFoundPage} />
        </Switch>

        <Footer
          color="white"
          size="normal"
          backgroundImage=""
          backgroundImageOpacity={1}
          copyright={`© ${new Date().getFullYear()} EcoSpace By Dijital İnovasyon ve Yapı Teknolojileri A.Ş.`}
          logo="../assets/EcoSpace.svg"
        />
      </>
    </Router>
  );
}

export default App;
