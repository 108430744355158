import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./FeaturesSection.scss";

function FeaturesSection(props) {
  const items = [
    {
      helper: "Kurumsal Gayrimenkul",
      title: (
        <span>
          Mekan Kullanım Verileri İle <br />{" "}
          <span className={"has-text-weight-bold"}>Kiradan Tasarruf Edin</span>
        </span>
      ),
      description:
        "Ofis alanının %40'ı kullanılmamaktadır. Daha iyi planlama ve fiyatlandırma kararları almak, operasyonları kolaylaştırmak ve daha iyi bir iş yeri deneyimi geliştirmek için alan kullanım verileriyle kiradan tasarruf edin.",
      image:
        "https://assets-global.website-files.com/60b908aee9c9240efc4b480e/60ee5566bc84e647ec4fed5c_office.jpg",
    },
    {
      helper: "Operasyon Yönetimi",
      title: (
        <span>
          Mekan Doluluğu Analizi ile <br />{" "}
          <span className={"has-text-weight-bold"}>
            İşletme Giderlerini Düşürün
          </span>
        </span>
      ),
      description:
        "Bina, kat, bölge ve oda düzeyinde kullanım bilgisi ile servis olanaklarınızı gerçek kullanıma göre ayarlayın ve yemek hizmetinden temizliğe, spor salonundan sergi alanlarına kadar hizmetleri iyileştirin, vardiyaları düzenleyin.",
      image:
        "https://assets-global.website-files.com/60b908aee9c9240efc4b480e/60ddee18b701fc1e39665d46_5%201%20(1).jpg",
    },
    {
      helper: "Enerji Tasarrufu",
      title: (
        <span>
          Yatırım Yapmadan
          <br />
          <span className={"has-text-weight-bold"}>Enerji Tasarrufu Yapın</span>
        </span>
      ),
      description: (
        <div>
          Enerji tüketimi, bina içerisindeki kişilerin mekân kullanımına ve kişi
          sayılarına doğrudan bağlıdır. Mekân içindeki gerçek zamanlı yoğunluk
          ölçümü ile havalandırma ve aydınlatma sistemleri kontrol edildiğinde
          %30’a kadar bir enerji tasarrufu sağlanır.
          <div className={"mt-3 has-text-weight-semibold"}>
            EcoSpace Mekanlarınızda boşa çalışan havalandırma ve aydınlatma olup
            olmadığını kontrol ederek enerji israfının önüne geçer.
          </div>
        </div>
      ),
      image:
        "https://assets-global.website-files.com/60b908aee9c9240efc4b480e/60da0b277725471b0d4d9f46_father-and-son-in-home-improvement-store-HWQZW84%20(1).jpg",
    },
    {
      helper: "Bina Otomasyonu",
      title: (
        <span>
          EcoSpace API ile
          <br />
          <span className={"has-text-weight-bold"}>
            Havalandırma & Aydınlatma Sistemlerini Düzenleyin
          </span>
        </span>
      ),
      description:
        "API, mekanlarınız için gerçek zamanlı kişi yoğunluğu ölçer ve alanların profillerini kaydeder. Temel JSON REST API'miz kişi sayısı, doluluk ve konum verilerini verir. Bu sayede binalar ve bina otomasyonları yeni çözümler oluşturulur. ",
      image: "../assets/EcoSpaceAPI.png",
    },
  ];

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <div className="has-text-centered">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={1}
            spaced={true}
            className="has-text-centered"
          />
        </div>
        <div className="FeaturesSection__features">
          {items.map((item, index) => (
            <div
              className="FeaturesSection__columns columns is-variable is-vcentered has-text-centered-mobile is-12"
              key={index}
            >
              <div className="column is-5">
                <p className={"mb-2 has-text-grey"}>{item.helper}</p>
                <h3 className="FeaturesSection__title title has-text-weight-normal is-spaced is-2">
                  {item.title}
                </h3>
                <p className="subtitle">{item.description}</p>
              </div>
              <div className="column">
                <figure className="FeaturesSection__image image">
                  <img src={item.image} alt={item.title} />
                </figure>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection;
