import React from "react";
import Section from "./Section";
import CenteredColumns from "./CenteredColumns";
import "./FeaturesSection2.scss";
import {
  AppWindow,
  ArrowsOut,
  Atom,
  BoundingBox,
  Cube,
  EyeClosed,
  FileCsv,
  HardDrives,
  Target,
  VideoCamera,
} from "phosphor-react";

function FeaturesSection2(props) {
  const items = [
    {
      icon: <Target size={32} weight="fill" />,
      title: "%95+ Üzerinde Tutarlılık",
      subtitle: "Kamera görüntülerindeki insanları %95+ doğruluk ile algılar. ",
      iconClass: "fas fa-gem",
      iconColor: "warning",
    },
    {
      icon: <EyeClosed size={32} weight="fill" />,
      title: "Yüz Algılama Yok, İzleme Yok!",
      subtitle:
        "GDPR uyumlu Eco Space AI teknolojimiz, insanların yüzlerini algılamaz tanımlama yapmaz. Anonim olarak insan vücudunu ve konumunu tespit eder.",
      iconClass: "fas fa-charging-station",
      iconColor: "danger",
    },
    {
      icon: <BoundingBox size={32} weight="fill" />,
      title: "Mevcut Altyapınızı Kullanır",
      subtitle:
        "EcoSpace platformu mevcut kameralarınızdan gelen görüntüyü analiz ederek çalışır. Kurulum, montaj ve yatırım yapmanıza gerek yoktur.",
      iconClass: "fas fa-adjust",
      iconColor: "primary",
    },

    {
      icon: <ArrowsOut size={32} weight="fill" />,
      title: "Ölçeklenebilir ve Genişletilebilir",
      subtitle:
        "Sistem entegratörleri ve geliştiriciler, EcoSpace verilerini üçüncü taraf uygulamalara entegre etmek için açık API'lerimizi kullanabilir.",
      iconClass: "fas fa-charging-station",
      iconColor: "danger",
    },
    {
      icon: <Cube size={32} weight="fill" />,
      title: "Ambians Algılama",
      subtitle:
        "İç mekan ortamındaki insanları algıladığı gibi, ortamın kendisini, ışıkların açık veya kapalı olduğunu anlar.",
      iconClass: "fas fa-charging-station",
      iconColor: "danger",
    },
    {
      icon: <Atom size={32} weight="fill" />,
      title: "Kendi Kendine Öğrenen Algoritma",
      subtitle:
        "EcoSpace AI platformu akıllıdır, mekanı analiz ettikçe daha tutarlılığı artar ve ileriye yönelik tahminlerde bulunur. ",
      iconClass: "fas fa-charging-station",
      iconColor: "danger",
    },
    {
      icon: <FileCsv size={32} weight="fill" />,
      title: "Anlık ve Geçmişe Dönük Veriler",
      subtitle:
        "Saniye başı güncelleme hızıyla Eco Space AI, gerçek zamanlı veriler sağlarken aynı zamanda geçmişe dönük verileri de gösterir.",
      iconClass: "fas fa-charging-station",
      iconColor: "danger",
    },
    {
      icon: <HardDrives size={32} weight="fill" />,
      title: "Sizin Sunucunuzda Çalışır",
      subtitle:
        "EcoSpace platformu, sizin kendi sunucularınıza çalışır. İster local ağınızda çalıştırın isterseniz bir servis sağlayıcının sunucusuna yükleyin.",
      iconClass: "fas fa-charging-station",
      iconColor: "danger",
    },
    {
      icon: <AppWindow size={32} weight="fill" />,
      title: "REST-API",
      subtitle:
        "Temel API'miz, JSON veya REST aracılığıyla kişilerin doluluk ve konum verilerini gönderir. Kendi ağınızda çalışan başka bir uygulama ile otomasyon sağlayabilirsiniz.",
      iconClass: "fas fa-charging-station",
      iconColor: "danger",
    },
  ];

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <CenteredColumns>
          {items.map((item, index) => (
            <div className="column has-text-left is-4" key={index}>
              <div className="FeaturesSection2__feature">
                <div>{item.icon}</div>
                <h1 className="has-text-weight-bold mb-2 is-size-5">
                  {item.title}
                </h1>
                <h2 className="subtitle is-6 has-text-grey-darker">
                  {item.subtitle}
                </h2>
              </div>
            </div>
          ))}
        </CenteredColumns>
      </div>
    </Section>
  );
}

export default FeaturesSection2;
