import React, { useState } from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Newsletter from "./Newsletter";
import "./NewsletterSection.scss";

function NewsletterSection(props) {
  const [subscribed, setSubscribed] = useState(false);
  const image =
    subscribed && props.imageSuccess ? props.imageSuccess : props.image;

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
              spaced={false}
            />
            <Newsletter
              buttonText={props.buttonText}
              buttonColor={props.buttonColor}
              buttonInverted={props.buttonInverted}
              inputPlaceholder={props.inputPlaceholder}
              subscribedMessage={props.subscribedMessage}
              onSubscribed={() => setSubscribed(true)}
            />
          </div>
          <div className="column is-1" />
          <div className="column is-4">
            <figure className="NewsletterSection__image image">
              <img src={image} alt="Illustration" />
            </figure>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default NewsletterSection;
