import React from "react";
import Meta from "./../components/Meta";
import FeaturesSection from "./../components/FeaturesSection";
import HeroSection from "./../components/HeroSection";
import ImageHero from "./../components/ImageHero";
import FeaturesSection2 from "./../components/FeaturesSection2";
import FeaturesSection3 from "./../components/FeaturesSection3";
import CtaSection from "./../components/CtaSection";
import NewsletterSection from "../components/NewsletterSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <FeaturesSection
        color="white"
        size="large"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Mekansal Analiz Platformu ile Tanışın"
        subtitle={
          <span>
            Binanızın neye ihtiyacı olduğundan emin değil misiniz? Fazla kira mı
            ödüyorsunuz? İş yeriniz hibrit düzene mi geçti? Enerji ve işletme
            maliyetinden tasarruf etmeniz mi gerekiyor? Yalnız değilsiniz.
            <strong> EcoSpace</strong> var!
          </span>
        }
      />
      <HeroSection
        color="white"
        size="small"
        backgroundImage=""
        backgroundImageOpacity={1}
        helper="Platform Yazılımı"
        title="EcoSpace Mekansal Zeka Yazılımı ile Tanışın"
        subtitle="EcoSpace Platformu şirketimizin geliştirdiği yapay Zeka algoritmalarını kullanan kamera tabanlı bir insan sayma platformudur. Zamana göre doluluğu algılamak ve gerçek zamanlı yoğunluk profili elde etmek için görüntülerdeki insanları algılar. "
        image="https://assets-global.website-files.com/60b908aee9c9240efc4b480e/60ee5566bc84e647ec4fed5c_office.jpg"
        buttonText="Kullanmaya Başlayın"
        buttonColor="black"
        buttonInverted={false}
        buttonPath="/register"
      />
      <ImageHero />
      <FeaturesSection2
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
      />
      <FeaturesSection3
        color="white"
        size="medium"
        backgroundImage=""
        title="Nasıl Uygulanır?"
        subtitle=""
        backgroundImageOpacity={1}
      />
      <NewsletterSection
        color="white"
        size="large"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Demo Talebi Oluşturun"
        subtitle="Size ulaşabilmemiz için aşağıdaki bilgileri eksiksiz doldurun."
        buttonText="Demo Talebi Oluştur"
        buttonColor="black"
        buttonInverted={false}
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed 🙌"
        image="https://assets-global.website-files.com/60b908aee9c9240efc4b480e/60ee5566bc84e647ec4fed5c_office.jpg"
        imageSuccess="https://uploads.divjoy.com/undraw-high_five_u364.svg"
      />
    </>
  );
}

export default IndexPage;
