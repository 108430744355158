import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./ClientsSection.scss";

function ClientsSection(props) {
  const items = [];

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />
        <div className="columns is-centered is-multiline">
          <div className="column is-narrow has-text-centered">
            <div className="ClientsSection__logo">
              <img src="../assets/EcoSpaceVentures.png" alt="Ventures" />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ClientsSection;
