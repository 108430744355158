import React from "react";

function ImageHero(props) {
  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-12">
            <div className="card is-shadowless">
              <div className="card-image">
                <figure className="image">
                  <img src="../assets/EcoSpacePlatform.png" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ImageHero;
