import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import CenteredColumns from "./CenteredColumns";
import "./FeaturesSection3.scss";

function FeaturesSection3(props) {
  const items = [
    {
      icon: "1",
      title: "EcoSpace Yazılımını Sisteminize Yüklüyoruz",
      subtitle:
        "Dockerize edilmiş EcoSpace yazılımını sizin vereceğiniz bir sunucuya veya servis sağlayıcının sunucusuna yüklüyoruz.",
      iconClass: "fas fa-gem",
      iconColor: "warning",
    },
    {
      icon: "2",
      title: "Mekan ve Kamera Bilgilerini Ekliyoruz",
      subtitle:
        "EcoSpace platformuna kameralarınıza ait RTPS ve IP bilgilerini sisteme giriyor ve mekanlarınız tanımlıyoruz.",
      iconClass: "fas fa-charging-station",
      iconColor: "danger",
    },
    {
      icon: "3",
      title: "Gerçek Zamanlı Mekansal Analizlere Erişiyorsunuz",
      subtitle:
        "EcoSpace platformu veya API aracılığıyla mekanlarınızın gerçek zamanlı Doluluk bilgilerini görüyorsunuz.",
      iconClass: "fas fa-charging-station",
      iconColor: "danger",
    },
  ];

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <div className="has-text-centered mb-5">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={2}
            spaced={true}
          />
        </div>
        <CenteredColumns>
          {items.map((item, index) => (
            <div className="column has-text-left is-4" key={index}>
              <div className="FeaturesSection3__feature">
                <h1 className={"is-size-2"}>{item.icon}</h1>
                <h1 className="has-text-weight-bold mb-2 is-size-4">
                  {item.title}
                </h1>
                <h2 className="subtitle is-6 has-text-grey-darker">
                  {item.subtitle}
                </h2>
              </div>
            </div>
          ))}
        </CenteredColumns>
      </div>
    </Section>
  );
}

export default FeaturesSection3;
