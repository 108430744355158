import React, { useState } from "react";
import { Link } from "./../util/router";

function Navbar(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav
      className={
        "navbar" +
        (props.color ? ` is-${props.color}` : "") +
        (props.spaced ? " is-spaced" : "")
      }
    >
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/">
              <img className="image" src={props.logo} alt="Logo" />
            </Link>
          </div>
          <div
            className={"navbar-burger burger" + (menuOpen ? " is-active" : "")}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
          <div className="navbar-end">
            <Link to="/about" className="navbar-item">
              Hakkımızda
            </Link>
            <Link to="/auth/signin" className="navbar-item">
              Giriş Yap
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
