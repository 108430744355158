import React, { useState } from "react";
import { useForm } from "react-hook-form";
import newsletter from "./../util/newsletter";
import axios from "axios";

function Newsletter(props) {
  const [subscribed, setSubscribed] = useState(false);
  const { register, errors } = useForm();

  return (
    <>
      {subscribed === false && (
        <form
          action="https://send.pageclip.co/a1Qi0mjdUbUe5g4M1iq17yPZZfe0oXSX"
          className="pageclip-form"
          method="post"
        >
          <div className="field is-grouped">
            <div className="control is-expanded">
              <div className={"label"}>Adınız ve Soyadınız</div>
              <input
                className="input is-medium"
                name="name"
                type="name"
                placeholder="Adınız ve Soyadınızı giriniz"
                ref={register({
                  required: "Please enter an email address",
                })}
              />
            </div>
            <div className="control is-expanded">
              <div className={"label"}>Kurumsal E-posta Adresiniz</div>
              <input
                className="input is-medium"
                name="email"
                type="email"
                placeholder="Eposta Adresinizi giriniz"
                ref={register({
                  required: "Please enter an email address",
                })}
              />
            </div>
          </div>
          <div className={"field"}>
            <div className={"control"}>
              <div className={"label"}>İrtibat Numaranızı Girin</div>
              <input
                className="input is-medium"
                name="phone"
                type="phone"
                placeholder="0540 123 45 67"
                ref={register({
                  required: "Please enter an email address",
                })}
              />
            </div>
          </div>
          <div className={"field"}>
            <div className="control">
              <button
                className={
                  "button is-medium" +
                  (props.buttonColor ? ` is-${props.buttonColor}` : "") +
                  (props.buttonInverted ? " is-inverted" : "")
                }
                type="submit"
              >
                {props.buttonText}
              </button>
            </div>
          </div>

          {errors.email && (
            <p className="help is-danger has-text-left">
              {errors.email.message}
            </p>
          )}
        </form>
      )}

      {subscribed === true && <div>{props.subscribedMessage}</div>}
    </>
  );
}

export default Newsletter;
