import React from "react";
import Meta from "./../components/Meta";
import NewsletterSection from "./../components/NewsletterSection";

function RegisterPage(props) {
  return (
    <>
      <Meta title="Register" />
      <NewsletterSection
        color="white"
        size="large"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Demo Talebi Oluşturun"
        subtitle="Size ulaşabilmemiz için aşağıdaki bilgileri eksiksiz doldurun."
        buttonText="Demo Talebi Oluştur"
        buttonColor="black"
        buttonInverted={false}
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed 🙌"
        image="https://assets-global.website-files.com/60b908aee9c9240efc4b480e/60ee5566bc84e647ec4fed5c_office.jpg"
        imageSuccess="https://uploads.divjoy.com/undraw-high_five_u364.svg"
      />
    </>
  );
}

export default RegisterPage;
